<template>
  <div class="chat--box-messages">
    <div
      :class="{
        'other--person': isPartnerMessage,
        'this--person': !isPartnerMessage,
      }"
    >
      <div class="message--content" v-html="sanitizedMessageBody"></div>
      <div class="message--time">
        {{ formattedCreatedAt }}
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import xss from 'xss';

export default {
  name: 'message-item',
  props: ['message'],
  computed: {
    ...mapState({
      currentUser: (state) => state.global.user,
    }),
    isPartnerMessage() {
      return this.currentUser.id != this.message.senderId;
    },
    formattedCreatedAt() {
      return this.$date.format(this.message.createdAt, 'dd MMM HH:mm');
    },
    sanitizedMessageBody() {
      const options = {
        whiteList: {
          br: [],
        },
      };
      return xss(this.message.messageBody, options);
    },
  },
};
</script>

<style scoped></style>
