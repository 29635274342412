<template>
  <div class="chat--box-bottom">
    <textarea
      rows="2"
      v-model="userInput"
      class="basic--input"
      :placeholder="$t('inbox.placeholder')"
    />
    <v-btn
      class="btn btn-primary btn--chat"
      @click="sendUserInput"
      :loading="fetchingMessages || loading"
      :disabled="validation.hasError('userInput')"
    >
      {{ $t('inbox.send') }}
    </v-btn>
    <!--    <button-->
    <!--      class="btn btn-primary"-->
    <!--    >-->
    <!--      inbox.send-->
    <!--    </button>-->
  </div>
</template>

<script>
import { Validator } from 'simple-vue-validator';
import { VBtn } from 'vuetify/lib';
export default {
  name: 'type-box',
  components: { VBtn },
  computed: {
    userInput: {
      get() {
        return this.$store.state.v2.myinbox.messageThreadDetail.userInput;
      },
      set(value) {
        this.$store.commit('v2/myinbox/messageThreadDetail/SET_USER_INPUT', value);
      },
    },
    loading() {
      return this.$store.state.v2.myinbox.messageThreadDetail.sendMessageLoading;
    },
    fetchingMessages() {
      return this.$store.state.v2.myinbox.messageThreadDetail.fetchingMessages;
    },
  },
  validators: {
    userInput: {
      validator(value) {
        return Validator.value(value).required();
      },
    },
  },
  methods: {
    sendUserInput() {
      this.$store.dispatch('v2/myinbox/messageThreadDetail/sendPrivateMessage');
    },
  },
  mounted() {
    this.$validate();
  },
};
</script>

<style scoped></style>
