<template>
  <section class="inbox--detail">
    <section-loader :show="loading" />
    <div class="inbox--detail-back" @click="$router.push('/inbox')">
      <div class="arrow">
        <i class="ion-ios-arrow-left"></i>
      </div>
      <div>
        {{ $t('general.btn.back') }}
      </div>
    </div>
    <chat-box v-if="userA" />
  </section>
</template>

<script>
import ChatBox from '@/components/inbox/partials/chat-box';
const SectionLoader = () => import('@/components/content-loading/section-loading');

export default {
  components: {
    ChatBox,
    SectionLoader,
  },
  computed: {
    userA() {
      return this.$store.state.v2.myinbox.messageThreadDetail.userA;
    },
    loading() {
      return this.$store.state.v2.myinbox.messageThreadDetail.fetchingMessages;
    },
  },
};
</script>
